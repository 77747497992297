var render = function render(){
  var _vm$board, _vm$board$writer, _vm$board2, _vm$board2$createdAt, _vm$board2$createdAt$, _vm$board3, _vm$board4, _vm$board4$files, _vm$board5, _vm$board5$files, _vm$board5$files$map, _vm$board8;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "v-card--default mt-20 mt-md-38",
    attrs: {
      "rounded": "xl",
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex flex-column pa-20 pa-md-36"
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "d-flex flex-wrap align-center mb-8"
  }, [_c('strong', {
    staticClass: "font-size-20 font-size-md-24 font-weight-bold grey-2--text mr-10"
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : (_vm$board$writer = _vm$board.writer) === null || _vm$board$writer === void 0 ? void 0 : _vm$board$writer.nickname))]), _c('div', {
    staticClass: "mt-2"
  }, [_c('v-row', {
    staticClass: "font-size-14 grey-a--text",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$createdAt = _vm$board2.createdAt) === null || _vm$board2$createdAt === void 0 ? void 0 : (_vm$board2$createdAt$ = _vm$board2$createdAt.toAgo) === null || _vm$board2$createdAt$ === void 0 ? void 0 : _vm$board2$createdAt$.call(_vm$board2$createdAt)) + " ")]), _vm.isUserWriter ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mx-8"
  }, [_vm._v("·")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-a"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`/community/boards/${_vm.board._id}/modify`);
      }
    }
  }, [_vm._v("수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mx-8"
  }, [_vm._v("·")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-a"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")])], 1)] : _vm._e()], 2)], 1)]), _c('item-texts', _vm._b({
    on: {
      "showsDetail": function (bool) {
        return _vm.showsDetail = bool;
      }
    }
  }, 'item-texts', {
    board: _vm.board,
    opened: _vm.opened,
    showsDetail: _vm.showsDetail
  }, false)), _c('div', {
    staticClass: "mt-10 mt-md-20"
  }, [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.tags) || [], function (tag) {
    return _c('v-chip', {
      key: tag,
      attrs: {
        "outlined": "",
        "small": ""
      }
    }, [_vm._v("#" + _vm._s(tag))]);
  }), 1)], 1)], 1), (((_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : (_vm$board4$files = _vm$board4.files) === null || _vm$board4$files === void 0 ? void 0 : _vm$board4$files.length) || 0) > 0 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('imgs-dialog', {
    attrs: {
      "imgs": ((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : (_vm$board5$files = _vm$board5.files) === null || _vm$board5$files === void 0 ? void 0 : (_vm$board5$files$map = _vm$board5$files.map) === null || _vm$board5$files$map === void 0 ? void 0 : _vm$board5$files$map.call(_vm$board5$files, function (_ref) {
        var url = _ref.url;
        return url;
      })) || [],
      "title": "게시판 이미지"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var _vm$board6, _vm$board6$files, _vm$board6$files$, _vm$board7, _vm$board7$files;
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('div', {
          staticClass: "w-100 w-lg-180px"
        }, [_c('v-img', _vm._g(_vm._b({
          staticClass: "rounded-lg",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": (_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : (_vm$board6$files = _vm$board6.files) === null || _vm$board6$files === void 0 ? void 0 : (_vm$board6$files$ = _vm$board6$files[0]) === null || _vm$board6$files$ === void 0 ? void 0 : _vm$board6$files$.url,
            "content-class": "d-flex justify-end align-end"
          }
        }, 'v-img', attrs, false), on), [_c('v-card', {
          staticClass: "d-flex align-center justify-center",
          staticStyle: {
            "border-radius": "12px 0px !important"
          },
          attrs: {
            "width": "36",
            "height": "36",
            "tile": "",
            "dark": "",
            "color": "rgba(0,0,0,0.5)"
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-light"
        }, [_vm._v(_vm._s((_vm$board7 = _vm.board) === null || _vm$board7 === void 0 ? void 0 : (_vm$board7$files = _vm$board7.files) === null || _vm$board7$files === void 0 ? void 0 : _vm$board7$files.length))])])], 1)], 1)];
      }
    }], null, false, 3958899093)
  })], 1) : _vm._e(), !!((_vm$board8 = _vm.board) !== null && _vm$board8 !== void 0 && _vm$board8.youtube) ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('dialog-window', {
    attrs: {
      "title": "게시판 비디오",
      "max-width": "660"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var attrs = _ref3.attrs,
          on = _ref3.on;
        return [_c('div', {
          staticClass: "w-100 w-lg-180px"
        }, [_c('v-responsive', {
          attrs: {
            "width": "100%",
            "aspect-ratio": 1 / 1
          }
        }, [_c('div', [_c('v-overlay', {
          staticClass: "rounded-lg hidden",
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('youtube', _vm._b({
          staticStyle: {
            "max-width": "100%"
          }
        }, 'youtube', {
          videoId: _vm.videoId
        }, false)), _c('v-overlay', _vm._g(_vm._b({
          attrs: {
            "absolute": "",
            "z-index": "0",
            "color": "transparent"
          }
        }, 'v-overlay', attrs, false), on))], 1)], 1)])], 1)];
      }
    }], null, false, 2187040635)
  }, [_c('div', {
    staticClass: "line-height-0 mb-n1"
  }, [_c('youtube', _vm._b({
    attrs: {
      "fitParent": ""
    }
  }, 'youtube', {
    videoId: _vm.videoId
  }, false))], 1)])], 1) : _vm._e()], 1), _c('v-expand-transition', [_vm.showsDetail ? _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('item-comments', _vm._b({
    on: {
      "syncComments": function (num) {
        return _vm.commentsLength = num;
      }
    }
  }, 'item-comments', {
    _board: _vm._board
  }, false))], 1) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showsDetail,
      expression: "!showsDetail"
    }],
    staticClass: "content-box-btn pt-10 pt-md-16 ml-n6",
    on: {
      "click": function ($event) {
        _vm.showsDetail = true;
      }
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg px-6",
    attrs: {
      "text": "",
      "color": "grey-2"
    }
  }, [_vm._v("전체보기 "), _c('v-icon', [_vm._v("mdi-chevron-down")])], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pa-16 px-md-26 primary white--text"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-comment-outline"
  }), _c('span', {
    staticClass: "font-size-14 ml-4"
  }, [_vm._v(_vm._s(_vm.commentsLength || 0))])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }