<template>
    <div>
        <div class="mb-20 mb-md-30">
            <div class="v-btn--group justify-end">
                <v-btn to="/community/boards/write" large color="primary" class="w-md-100 mw-md-100px">글쓰기</v-btn>
            </div>
        </div>
        <item v-for="board in boards" :key="board._id" v-bind="{ board }" />
        <slot name="pagination"></slot>
    </div>
</template>

<script>
import Item from "./item.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
export default {
    components: {
        Item,
        PaginationComponent,
    },
    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    methods: {
        changePage() {
            this.$emit("page", this.page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>
