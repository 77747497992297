var render = function render(){
  var _vm$summary;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 grey-2--text"
  }, [_vm._v("댓글 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : _vm$summary.totalCount))])])]), _c('v-divider', {
    staticClass: "border-2 grey-2 mt-8 mt-md-14"
  }), _c('comments', {
    attrs: {
      "value": _vm.items
    },
    on: {
      "save": _vm.save,
      "remove": _vm.remove
    }
  }), _vm.isLoggedIn ? _c('div', {
    staticClass: "pt-16"
  }, [_vm.isLoggedIn ? _c('comment-input', {
    attrs: {
      "relative": ""
    },
    on: {
      "save": _vm.save
    }
  }) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }