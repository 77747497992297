var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-20 mb-md-30"
  }, [_c('div', {
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "to": "/community/boards/write",
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("글쓰기")])], 1)]), _vm._l(_vm.boards, function (board) {
    return _c('item', _vm._b({
      key: board._id
    }, 'item', {
      board
    }, false));
  }), _vm._t("pagination")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }