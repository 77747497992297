<template>
    <div :class="className" ref="content-box" :style="style" @click="$emit('showsDetail', true)">{{ board?.content }}</div>
</template>

<script>
export default {
    props: {
        board: { type: Object, default: () => ({}) },
        opened: { type: Boolean, default: false },
        showsDetail: { type: Boolean, default: false },
    },
    computed: {
        style() {
            const { scrollHeight } = this.$refs["content-box"] || {};
            if (this.opened || this.showsDetail) {
                if (scrollHeight) return `white-space:pre-line; max-height:${scrollHeight}px;`;
                return `white-space: pre-line; max-height: initial;`;
            } else return `white-space: pre-line`;
        },
        className() {
            let className = "content-box";
            if (this.opened || this.showsDetail) className += " content-box--shows";
            return className;
        },
    },
};
</script>

<style></style>
