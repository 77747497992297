var render = function render(){
  var _vm$board;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "content-box",
    class: _vm.className,
    style: _vm.style,
    on: {
      "click": function ($event) {
        return _vm.$emit('showsDetail', true);
      }
    }
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.content))]);

}
var staticRenderFns = []

export { render, staticRenderFns }