<template>
    <v-card rounded="xl" outlined class="v-card--default mt-20 mt-md-38">
        <v-card-text class="d-flex flex-column pa-20 pa-md-36">
            <v-row>
                <v-col>
                    <div class="d-flex flex-wrap align-center mb-8">
                        <strong class="font-size-20 font-size-md-24 font-weight-bold grey-2--text mr-10">{{ board?.writer?.nickname }}</strong>
                        <div class="mt-2">
                            <v-row no-gutters align="center" class="font-size-14 grey-a--text">
                                <v-col cols="auto">
                                    {{ board?.createdAt?.toAgo?.() }}
                                </v-col>
                                <template v-if="isUserWriter">
                                    <v-col cols="auto"><span class="mx-8">·</span></v-col>
                                    <v-col cols="auto">
                                        <v-btn @click="$router.push(`/community/boards/${board._id}/modify`)" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">수정</v-btn>
                                    </v-col>
                                    <v-col cols="auto"><span class="mx-8">·</span></v-col>
                                    <v-col cols="auto">
                                        <v-btn @click="remove" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">삭제</v-btn>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                    </div>

                    <item-texts v-bind="{ board, opened, showsDetail }" @showsDetail="(bool) => (showsDetail = bool)" />

                    <div class="mt-10 mt-md-20">
                        <v-chip-group column>
                            <v-chip v-for="tag in board?.tags || []" :key="tag" outlined small>#{{ tag }}</v-chip>
                        </v-chip-group>
                    </div>
                </v-col>

                <v-col v-if="(board?.files?.length || 0) > 0" cols="12" md="auto">
                    <imgs-dialog :imgs="board?.files?.map?.(({ url }) => url) || []" title="게시판 이미지">
                        <template #activator="{attrs,on}">
                            <div class="w-100 w-lg-180px">
                                <v-img v-bind="attrs" v-on="on" width="100%" :aspect-ratio="1 / 1" :src="board?.files?.[0]?.url" class="rounded-lg" style="cursor:pointer;" content-class="d-flex justify-end align-end">
                                    <v-card width="36" height="36" tile dark color="rgba(0,0,0,0.5)" class="d-flex align-center justify-center" style="border-radius: 12px 0px !important">
                                        <span class="font-size-14 font-weight-light">{{ board?.files?.length }}</span>
                                    </v-card>
                                </v-img>
                            </div>
                        </template>
                    </imgs-dialog>
                </v-col>

                <v-col v-if="!!board?.youtube" cols="12" md="auto">
                    <dialog-window title="게시판 비디오" max-width="660">
                        <template #activator="{attrs,on}">
                            <div class="w-100 w-lg-180px">
                                <v-responsive width="100%" :aspect-ratio="1 / 1">
                                    <div>
                                        <v-overlay absolute z-index="0" class="rounded-lg hidden">
                                            <youtube v-bind="{ videoId }" style="max-width:100%" />
                                            <v-overlay absolute z-index="0" color="transparent" v-bind="attrs" v-on="on"> </v-overlay>
                                        </v-overlay>
                                    </div>
                                </v-responsive>
                            </div>
                        </template>
                        <div class="line-height-0 mb-n1">
                            <youtube v-bind="{ videoId }" fitParent />
                        </div>
                    </dialog-window>
                </v-col>
            </v-row>

            <v-expand-transition>
                <div class="mt-30 mt-md-50" v-if="showsDetail">
                    <item-comments v-bind="{ _board }" @syncComments="(num) => (commentsLength = num)" />
                </div>
            </v-expand-transition>

            <div v-show="!showsDetail" @click="showsDetail = true" class="content-box-btn pt-10 pt-md-16 ml-n6">
                <v-btn text color="grey-2" class="v-btn--none-active-bg px-6">전체보기 <v-icon>mdi-chevron-down</v-icon></v-btn>
            </div>
        </v-card-text>

        <v-card-actions class="pa-16 px-md-26 primary white--text">
            <v-row align="center" class="row--small">
                <v-col cols="auto">
                    <div class="d-flex align-center">
                        <i class="icon icon-comment-outline"></i>
                        <span class="font-size-14 ml-4">{{ commentsLength || 0 }}</span>
                    </div>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import ImgsDialog from "@/components/dumb/imgs-dialog.vue";
import ItemComments from "./item-comments.vue";
import urlParser from "js-video-url-parser";
import DialogWindow from "@/components/dumb/dialog-window.vue";
import api from "@/api";
import ItemTexts from "./item-texts.vue";
export default {
    components: {
        ItemComments,
        ImgsDialog,
        DialogWindow,
        ItemTexts,
    },
    props: {
        board: { type: Object, default: () => ({}) },
        opened: { type: Boolean, default: false },
    },
    data: () => ({
        showsDetail: false,
        commentsLength: 0,
    }),
    computed: {
        _board() {
            return this.board?._id;
        },
        isUserWriter() {
            return this.board?.writer?._id == this.$store?.state?.payload?._user;
        },
        videoId() {
            return urlParser.parse(this.board?.youtube)?.id || "";
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.showsDetail = this.opened;
            this.commentsLength = this.board?._comments?.length || 0;
        },

        async remove() {
            const go = confirm("게시물을 삭제하시겠습니까?\n\n한 번 삭제된 게시물을 복구되지 않습니다.");

            if (go) {
                await api.v1.boards.delete(this.board);
                alert("성공적으로 처리되었습니다.");
                if (!!this.$route.params._board) this.$router.go(-1);
                else if (!this.$route.params._board) this.$router.go();
            }
        },
    },
};
</script>

<style scoped>
.v-card--default{
    min-height: 360px;
}
.content-box{
    max-height: 60px;
    min-height: 60px;
}
</style>